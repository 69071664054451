import Keycloak from 'keycloak-js';

const auth = new Keycloak(
  process.env.REACT_APP_ENV === 'DEVELOPMENT'
    ? '/keycloak-test.json'
    : '/keycloak.json',
);

let autheticated = false;

let user = {};

const login = async () => {
  try {
    autheticated = await auth
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        // redirectUri: window.location.origin + '/passe-carros',
      })
      .then((autheticated) => {
        return autheticated;
      })
      .catch((error) => {
        return false;
      });
    return autheticated;
  } catch (error) {
    return false;
  }
};

const getProfile = async () => {
  try {
    await auth
      .loadUserProfile()
      .then((profile) => {
        user = profile;
      })
      .catch(() => {
        console.log('error');
      });
    return user;
  } catch (error) {
    return false;
  }
};

const getToken = () => {
  return auth.token;
};

const isLogged = () => {
  const token = getToken();
  return !!token;
};

const updatePassword = () => {
  // const redirectUri = routesPath.getRecursivePath(routesPath.mobigestorHome);
  return auth.login({
    redirectUri: '/passe-carros',
    action: 'UPDATE_PASSWORD',
  });
};

const logout = () => {
  auth.logout({
    redirectUri: window.location.origin,
  });
  localStorage.removeItem('dealerId');
};

export { login, getProfile, updatePassword, getToken, isLogged, logout };

export default auth;
