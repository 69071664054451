import { Theme as MuiTheme, createTheme } from '@mui/material';
import getEnvVarByName from 'helpers/getEnvVarByName';

const primaryColor = getEnvVarByName('PRIMARY_COLOR');
const secondaryColor = getEnvVarByName('SECONDARY_COLOR');

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        root: {
          body: {
            '-webkit-scrollbar-thumb': {
              scrollBarColor: '#63c0b0',
            },
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {},
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
  },
});

export const defaultTheme = {
  typography: {
    fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
    titleDesktop: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '42px',
    },
    subtitleDesktop: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '32px',
    },
    titleMobile: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '24px',
    },
    subtitle1: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '28px',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '21px',
    },
    subtitleMobile: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '26px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '26px',
    },
    body2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
    },
    body3: {
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '17px',
    },
    button: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '24px',
    },
    linkSmall: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '22px',
      textDecoration: 'none',
    },
    linkSmallHover: {
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '22px',
      textDecoration: 'underline',
    },
    linkMedium: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      textDecoration: 'none',
    },
    linkMediumHover: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',
      textDecoration: 'underline',
    },
    linkLarge: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
      textDecoration: 'none',
    },
    linkLargeHover: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '22px',
      textDecoration: 'underline',
    },
    menu: {
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: '22px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: 'Poppins, Helvetica, Arial, sans-serif',
        variantMapping: {
          titleDesktop: 'h2',
          subtitleDesktop: 'h3',
          titleMobile: 'h2',
          subtitleMobile: 'h3',
          body3: 'p',
          menu: 'h4',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'medium' && {
            '& .MuiInputBase-input.MuiOutlinedInput-input': {
              padding: '12.5px 14px',
            },
          }),
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.size === 'medium' && {
            height: '48px',
          }),
        }),
      },
    },
    MuiCssBaseline: {
      styleOverrides: (themeParams) => ({
        html: {
          backgroundColor: `#ffffff`,

          scrollbarColor: `${themeParams?.palette?.primary?.main} #ffffff`,
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: `#ffffff`,
            width: '7px',
            scrollbarColor: `${themeParams?.palette?.primary?.main} #ffffff`,
          },
        },

        body: {
          backgroundColor: '#fafafa',
          scrollbarColor: `${themeParams?.palette?.primary?.main} #ffffff`,
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#ffffff',
            width: '7px',
            scrollbarColor: `${themeParams?.palette?.primary?.main} #ffffff`,
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: 8,
            backgroundColor: ' #ffffff',
            minHeight: 24,
          },

          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#ffffff',
          },
          '& .swiper-pagination-bullet-active': {
            background: `${themeParams?.palette?.primary?.main}`,
            opacity: 1,
            width: '16px',
            height: '8px',
            borderRadius: '20px',
          },

          '& .swiperCustomHeight .swiper-slide-thumb-active': {
            opacity: 1,
            border: `2px solid ${themeParams?.palette?.primary?.main}`,
            borderRadius: '8px',
          },
        },
      }),
    },
    MuiStep: {
      styleOverrides: {
        root: ({ theme }) => ({
          '.Mui-active .MuiStepConnector-line': {
            backgroundColor: theme.palette.primary.main,
          },
          '.Mui-active .MuiStepIcon-text': {
            fill: '#fff',
          },
        }),
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        alternativeLabel: {
          top: 21,
        },
        active: ({ theme }) => ({
          '.MuiStepConnector-line, .MuiStepConnector-lineHorizontal': {
            backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%)`,
          },
        }),
        completed: ({ theme }) => ({
          '.MuiStepConnector-line, .MuiStepConnector-lineHorizontal': {
            backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%)`,
          },
        }),
        line: {
          height: 2,
          border: 0,
          borderColor: 'unset',
          backgroundColor: '#eaeaf0',
          borderRadius: 1,
        },
        root: {
          '.Mui-active .MuiStepConnector-line': {
            backgroundImage:
              'linear-gradient(90deg, rgb(123, 38, 153) 0%, rgb(7, 178, 253) 50%)',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        active: ({ theme }) => ({
          backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%)`,
          boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
          border: '1px solid transparent !important',
        }),
        completed: ({ theme }) => ({
          backgroundImage: `linear-gradient(90deg, ${theme.palette.primary.main} 0%,${theme.palette.primary.main} 50%)`,
          border: '1px solid transparent !important',
        }),
        root: ({ theme }) => ({
          backgroundColor: '#fff',
          zIndex: 1,
          color: '#fff',
          border: '1px solid #ccc',
          width: 44,
          height: 44,
          display: 'flex',
          borderRadius: '50%',
          justifyContent: 'center',
          alignItems: 'center',
        }),
        text: ({ theme }) => ({
          fill: theme.palette.other.backdrop,
          fontSize: '10px',
        }),
      },
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      dark: `${primaryColor}`,
      light: primaryColor,
      contrastText: '#e0f3f1',
      activeContained: '#4c978e',
      hover: '#f0f6f6',
      selected: '#e6f2f1',
      active: '#c2e7e3',
      outlinedStrokeBorder: '#7dcac2',
    },
    secondary: {
      main: secondaryColor,
      dark: `${secondaryColor}73`,
      light: secondaryColor,
      contrastText: '#e9eaf9',
      activeContained: '#4C5FAE',
      hover: '#F8F7FC',
      selected: '#F0F0FA',
      activeOutlined: '#D2D2EF',
      outlinedStroke: '#9F9FDB',
    },
    actions: {
      border: '#757575',
      hover: '#F5F5F5',
      selected: '#EEEEEE',
      disabledBackground: '#E0E0E0',
      outlinedStroke: '#BDBDBD',
      disabledText: '#BDBDBD',
      gray50: '#FAFAFA',
    },
    other: {
      primary: '#424242',
      secondary: '#757575',
      disabled: '#BDBDBD',
      contrastText: '#FFFFFF',
      divider: '#E0E0E0',
      backdrop: '#808080',
      snackbarBackground: '#212121',
      activeRating: '#FFB400',
      tapHighlightColor: '#8fdaf1',
    },
    info: {
      main: '#07B2FD',
      dark: '#0B79D0',
      light: '#64B6F7',
      darkText: '#0B5895',
      lightBg: '#E9F5FE',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#F44336',
      dark: '#E31B0C',
      light: '#F88078',
      darkText: '#621B16',
      lightBg: '#FEECEB',
      contrastText: '#FFFFFF',
    },
    warning: {
      main: '#ED6C02',
      dark: '#C77700',
      light: '#FFB547',
      darkText: '#663D00',
      lightBg: '#FFF5E5',
    },
  },
  boxShadows: {
    none: 'none',
    smallest: '0px 1px 2px rgba(0, 0, 0, 0.16), 0px 1px 2px rgba(0, 0, 0, 0.1)',
    small: '0px 2px 4px rgba(0, 0, 0, 0.16), 0px 2px 4px rgba(0, 0, 0, 0.1)',
    medium: '0px 4px 6px rgba(0, 0, 0, 0.16), 0px 4px 6px rgba(0, 0, 0, 0.1)',
  },
  borderRadius: {
    none: '0px',
    smallest: '2px',
    small: '4px',
    medium: '8px',
    big: '16px',
    circle: '50%',
  },
  strokes: {
    none: '0px',
    smallest: '1px',
    small: '2px',
    medium: '4px',
    big: '8px',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

export default defaultTheme;

export type DefaultThemeCustomValues = typeof defaultTheme;
export type DefaultTheme = DefaultThemeCustomValues & MuiTheme;
